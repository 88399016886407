import { Divider } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { useIsMobile } from '@xyo-network/react-shared'

import { TzeroListingBar } from '../../common'
import { OrganizationRichResult } from '../../RichResults'
import BasePage from '../BasePage'
// eslint-disable-next-line import/no-cycle
import { HomePageDataSection } from './Data'
import { HomePageHero } from './Hero'
import { InvestorSection } from './InvestorSection'
import { ProductsSection } from './ProductsSection'
import { XYOSection } from './XYOSection'

const onClickIdScrollIntoView = (elementId: string) => () => {
  const section = document.querySelector(elementId)
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

export const HomePage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <BasePage name="Home" positioningAppbar={false}>
      <OrganizationRichResult />
      <FlexGrowCol height="100vh" maxHeight="1000px" alignItems="stretch">
        <HomePageHero
          title=""
          title2="Data Made for Everyone"
          animatedText={['Simple', 'Trustless', 'Self-Sovereign', 'Readable', 'Standardized', 'Beautiful', 'Actionable', 'Consensusless']}
          desc="XY Labs connects data between the real and digital worlds through blockchain, IoT, and
        cryptographic products. XYO, our self-sovereign data platform, is our key product."
          button1Text="Learn More"
          buttonOnClick={onClickIdScrollIntoView('#dataCore')}
        />
        {isMobile ? null : <TzeroListingBar />}
      </FlexGrowCol>
      {isMobile ?
        <FlexGrowCol>
          <TzeroListingBar />
        </FlexGrowCol>
      : null}
      <div id="dataCore" />
      <HomePageDataSection />
      <div id="products" />
      <ProductsSection />
      <Divider />
      <InvestorSection />
      <Divider />
      <div id="xyoProduct" />
      <XYOSection />
    </BasePage>
  )
}
