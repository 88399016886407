import { Toolbar } from '@mui/material'
import { ButtonEx, FlexRow } from '@xylabs/sdk-react'

const SystemToolBar: React.FC = () => {
  return (
    <Toolbar disableGutters>
      <FlexRow sx={{ display: { md: 'inherit', xs: 'none' } }}>
        <ButtonEx variant="text" to={{ hash: '#mission' }}>
          Mission
        </ButtonEx>
        <ButtonEx variant="text" to={{ hash: '#who' }}>
          Who
        </ButtonEx>
        <ButtonEx variant="text" to={{ hash: '#location' }}>
          Location
        </ButtonEx>
      </FlexRow>
      <ButtonEx to={{ hash: '#opportunities' }} marginLeft={1} variant="outlined">
        Careers
      </ButtonEx>
    </Toolbar>
  )
}

export default SystemToolBar
