import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
// eslint-disable-next-line import/no-internal-modules
import ReactPlayer, { WistiaPlayerProps } from 'react-player/wistia'

const WistiaPlayer = ReactPlayer as unknown as React.FC<WistiaPlayerProps>

const CoinVideo: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container justifyContent="center" alignItems="center" flexDirection="column">
          <Grid item xs={12} md={6}>
            <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="center" sx={{ letterSpacing: 2 }} gutterBottom>
              COIN rewards you for sharing data!
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom textAlign="center">
              COIN app is one of the first of its kind: Most apps use your data for free, but COIN rewards you for it!
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <FlexGrowCol height="auto" width="auto" style={{ paddingTop: '56.25%', position: 'relative' }} marginY={3}>
              <WistiaPlayer
                style={{ left: 0, position: 'absolute', top: 0 }}
                controls={true}
                playing={true}
                muted
                url="https://jf3.wistia.com/medias/m4m5thne4w"
                height="100%"
                width="100%"
              />
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default CoinVideo
