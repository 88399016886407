import { QuestionAnswerRounded } from '@mui/icons-material'
import { Container, Divider, Grid } from '@mui/material'
import { FlexGrowRow } from '@xylabs/sdk-react'
import { SimpleCard } from '@xyo-network/react-card'
import { SectionSpacingRow, useIsMobile } from '@xyo-network/react-shared'

import { BasicHero, CommonImages } from '../../../common'
import XyBasePage from '../../BasePage'
import InvestorResourcesData from './InvestorResourcesData'

export const InvestorsResourcesPage: React.FC = () => {
  const isMobile = useIsMobile()
  return (
    <XyBasePage name="Investor Resources">
      <BasicHero
        title="Shareholder Resources"
        desc="We received qualification to sell our first round of Reg A+ Equity in 2016. We now have over 20,000 shareholders from around the world!"
        button1Text="Investor FAQ"
        button1To="/investors/faq"
        button2To="/investors/how-to-buy"
        button2Text="How to Buy Equity"
        subLinkPath="/investors/support"
        subLinkText1="Need Help With Your Equity?"
        subLinkText2="Get Support Here."
        subLinkIcon={<QuestionAnswerRounded />}
        backgroundImage={isMobile ? '' : CommonImages.booksRight}
      />
      <Divider />
      <FlexGrowRow>
        <Container>
          <SectionSpacingRow>
            <Grid container spacing={2}>
              {InvestorResourcesData.map((props, index) => {
                return (
                  <Grid item key={index} xs={12} md={6}>
                    <SimpleCard sx={{ height: '100%' }} {...props} />
                  </Grid>
                )
              })}
            </Grid>
          </SectionSpacingRow>
        </Container>
      </FlexGrowRow>
    </XyBasePage>
  )
}
