import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowCol, LinkEx } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

import { TzeroMacbook } from './img'

export const TzeroExplained: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <SectionSpacingRow>
      <Container>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={8}>
            <FlexGrowCol paddingBottom={3}>
              <Typography variant="h4" textAlign="center" gutterBottom>
                tZERO
              </Typography>
              <Typography variant="body1" textAlign="justify" gutterBottom>
                <strong>tZERO</strong> is a fin-tech holding company that offers a digital securities trading platform and cryptocurrency trading
                platform through its regulated subsidiaries. Its digital securities trading platform is offered by tZERO Markets and tZERO ATS.{' '}
                <strong>tZERO Markets</strong> is an introducing broker-dealer for tZERO ATS. tZERO Markets is registered with FINRA and the SEC and
                is a member of the Securities Investor Protection Corporation. tZERO&apos;s cryptocurrency trading platform is offered by tZERO
                Crypto. <strong>tZERO Crypto, Inc.</strong> provides a mobile-based cryptocurrency trading service which provides a simple,
                user-friendly avenue for individuals looking to purchase, store, and transact in cryptocurrencies.
              </Typography>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={10} md={6}>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Resources &amp; Instructions
            </Typography>
            <ul>
              <li>
                <LinkEx to="#tzeroAccount">How to make a tZERO Markets Account</LinkEx>
              </li>
              <li>
                <LinkEx to="#requestDeposit">How to request a deposit of XY Shares from Vertalo to tZERO</LinkEx>
              </li>
              <li>
                <LinkEx to="#tzeroFaq">tZERO FAQ</LinkEx>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <FlexGrowCol>
              <img src={TzeroMacbook} width={isMobile ? '100%' : '80%'} height="auto" />
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}
