import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, alpha, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Override } from '@xylabs/sdk-js'
import { ReactNode, useState } from 'react'

import { OpportunityProps } from './opportunityData'

interface JobAccordionCardAdditionalProps extends AccordionProps, OpportunityProps {
  dark?: boolean
  key: number
}

interface OptionalChildren {
  children?: ReactNode
}

//this makes the requirement for children from AccordionProps go away
type JobAccordionCardProps = Override<JobAccordionCardAdditionalProps, OptionalChildren>

const JobAccordionCard: React.FC<JobAccordionCardProps> = ({ jobTitle, desc, compensation, experience, key, tools, dark = false, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [expanded, setExpanded] = useState<string | false>(false)
  const keyString = key + 'card'
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion
      expanded={expanded === keyString}
      onChange={handleChange(keyString)}
      elevation={0}
      style={{
        backgroundColor: dark ? undefined : alpha(theme.palette.primary.main, 0.05),
      }}
      {...props}
    >
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography variant="h6" textAlign="left" gutterBottom={isMobile ? true : false}>
          {jobTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" textAlign="left">
          {desc}
        </Typography>
        <Typography variant="body1" textAlign="left">
          <Typography>Compensation:&nbsp;</Typography>
          {compensation}
        </Typography>
        <Typography variant="body1" textAlign="left">
          <Typography>Experience:&nbsp;</Typography>
          {experience}
        </Typography>
        <Typography variant="body1" textAlign="left">
          <Typography>Tools:&nbsp;</Typography>
          {tools}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default JobAccordionCard
