import { CoinLogo, XyoLogo } from '../img'
import OpportunityProps from './OpportunityProps'

const marketingRevenueOpportunities: OpportunityProps[] = [
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Media Buyer (Google)',
    logos: [CoinLogo],
    tools: 'Google Ads',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Media Buyer (Facebook)',
    logos: [CoinLogo],
    tools: 'Facebook Ads',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Media Buyer (Other)',
    logos: [CoinLogo],
    tools: 'Snapchat Ads, Tiktok Ads, Etc...',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '4+ year',
    jobTitle: 'Director of Marketing',
    logos: [CoinLogo],
    tools: 'Facebook Ads, Google Ads, App Store, Play Store, AppsFlyer',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '2+ year',
    jobTitle: 'SEO Expert',
    logos: [CoinLogo],
    tools: 'App Store, Play Store, Google Search',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Email Marketing Manager',
    logos: [CoinLogo],
    tools: 'Drip, Dmarcian',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Social Media Marketing Manager',
    logos: [CoinLogo],
    tools: 'Facebook, Twitter, Instagram, Reddit, Medium',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Public Relations Manager',
    logos: [XyoLogo, CoinLogo],
    tools: 'Microsoft Word, Google Docs, PR News Wire',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Graphic Designer',
    logos: [CoinLogo, XyoLogo],
    tools: 'Adobe Suite',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '1+ year',
    jobTitle: 'Copywriter',
    logos: [CoinLogo],
    tools: 'Microsoft Word, Google Docs',
  },
]

export default marketingRevenueOpportunities
