import { Card, CardHeader, Container, Grid, Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexGrowRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

interface WhoData {
  heading: string
  subheading: string
}

const whoData: WhoData[] = [
  {
    heading: 'Think outside the box?',
    subheading: '(or was there even a box in the first place?)',
  },
  {
    heading: 'Love to learn?',
    subheading: '(hitting up bar trivia & re-watching Jeopardy?)',
  },
  {
    heading: 'Enjoy the journey?',
    subheading: "(..it's the experiences we had along the way)",
  },
  {
    heading: 'Make work a part of life?',
    subheading: '(being off the clock ≠ the thinking has to stop!)',
  },
]

const Who: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  return (
    <SectionSpacingRow>
      <Container>
        <FlexCol alignItems="stretch" {...props}>
          <FlexCol justifyContent="flex-start" alignItems="flex-start">
            <Typography flexWrap="wrap" variant="h3" gutterBottom>
              Who should be part of the XY Labs team?
            </Typography>
            <Typography flexWrap="wrap" variant="h6" gutterBottom>
              We’re looking for people who truly, deeply care about the work they do. People who look at each project, no matter how small, and do
              their best to do it right.
            </Typography>
            <Typography flexWrap="wrap" variant="h6" gutterBottom>
              We’re looking for people who view the world as an opportunity for learning &amp; growth, and who are self-sufficient. People who can
              know when to Google “How to _” for a new project and when to ask for help.
            </Typography>
            <Typography flexWrap="wrap" variant="h6" gutterBottom>
              In other words, we’re looking for YOU.
            </Typography>
          </FlexCol>
          <FlexGrowRow justifyContent="flex-start" alignItems="stretch" minWidth={300} marginY={2} paddingX={2}>
            <Grid container>
              {whoData?.map((who, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <FlexCol alignItems="stretch" position="relative" margin={1}>
                    <Card
                      square
                      style={{
                        background: theme.palette.primary.main,
                        bottom: theme.spacing(-2),
                        minHeight: '100px',
                        width: '100%',
                      }}
                    >
                      <CardHeader
                        title={<Typography variant="h4">{who.heading}</Typography>}
                        subheader={<Typography variant="subtitle2">{who.subheading}</Typography>}
                      />
                    </Card>
                    <Card
                      square
                      style={{
                        backgroundColor: theme.palette.primary.dark,
                        bottom: theme.spacing(1),
                        color: theme.palette.primary.contrastText,
                        minHeight: '100px',
                        position: 'absolute',
                        right: theme.spacing(1),
                        width: '100%',
                      }}
                    >
                      <CardHeader
                        title={<Typography variant="h4">{who.heading}</Typography>}
                        subheader={<Typography variant="body1">{who.subheading}</Typography>}
                      />
                    </Card>
                  </FlexCol>
                </Grid>
              ))}
            </Grid>
          </FlexGrowRow>
        </FlexCol>
      </Container>
    </SectionSpacingRow>
  )
}

export default Who
