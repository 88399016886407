import { UserClickFields, ViewContentFields } from '@xylabs/pixel'
import { XyCustomEvent } from '@xylabs/sdk-react'

class XyEvents<T extends Record<string, unknown>> {
  userClick() {
    return new XyCustomEvent<UserClickFields | T>('UserClick')
  }

  viewContent() {
    return new XyCustomEvent<ViewContentFields | T>('ViewContent')
  }
}

// eslint-disable-next-line import/no-default-export
export default XyEvents
