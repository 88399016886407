import { CoinLogo, XyoLogo } from '../img'
import OpportunityProps from './OpportunityProps'

const productDevelopmentOpportunities: OpportunityProps[] = [
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '3+ years',
    jobTitle: 'iOS Developer',
    logos: [CoinLogo, XyoLogo],
    tools: 'Swift 5, XCode, Swift Packages, Mapbox',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '3+ years',
    jobTitle: 'Android Developer',
    logos: [CoinLogo, XyoLogo],
    tools: 'Kotlin, Android Studio, Jitpack, Mapbox',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '3+ years',
    jobTitle: 'React Developer',
    logos: [CoinLogo, XyoLogo],
    tools: 'TypeScript, Material UI, NPMJS, vsCode, ESLint',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '3+ years',
    jobTitle: 'NodeJS Developer',
    logos: [CoinLogo, XyoLogo],
    tools: 'NodeJS, TypeScript, Serverless, NPMJS, vsCode, ESLint',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '2+ years',
    jobTitle: 'Blockchain Developer',
    logos: [CoinLogo, XyoLogo],
    tools: 'Solidity, Truffle, Ganache, OpenZeppelin, vsCode, Infura',
  },
  {
    compensation: 'Market/DOE',
    desc: '',
    experience: '3+ years',
    jobTitle: 'Database Administrator',
    logos: [CoinLogo],
    tools: 'MongoDB, Mongo Atlas, vsCode, BigQuery, GraphQL',
  },
  {
    compensation: '$85k+',
    desc: '',
    experience: '2+ year',
    jobTitle: 'QA/DevOps Engineer',
    logos: [XyoLogo],
    tools: 'OpenSource, Git, vsCode, Github Actions, AWS, Google Cloud',
  },
]

export default productDevelopmentOpportunities
