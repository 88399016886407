import { SimpleCardProps } from '@xyo-network/react-card'

export const FaqData: SimpleCardProps[] = [
  {
    desc: "tZERO ATS is the marketplace where XY Labs' shares and the securities of other companies can be traded.",
    headline: 'What is tZERO ATS?',
    href: 'https://www.tzero.com/digital-securities',
  },
  {
    desc: "tZERO's website offers multiple FAQs and outlines the benefits of their platform. Click the Learn More button to visit tZERO's website and learn more about tZERO's platform for digital securities.",
    headline: 'Where can I learn more about tZERO Markets?',
    href: 'https://www.tzero.com/digital-securities',
  },
  {
    desc: "XY Labs Stock and XYO Tokens are separate assets that are not interchangeable. This means they cannot be traded for one another. XY Labs Stock is Class A Common Stock of XY Labs. The stock represents ownership in the company. This is the same kind of stock you might see on a trading exchange — it is not a cryptocurrency. XYO Tokens on the other hand, are a cryptocurrency. Technically, XYO Tokens are an ERC-20 utility token, which is built on the Ethereum blockchain. You can trade XYO Tokens on exchanges like Coinbase, Crypto.com, etc. Coming soon XYO will be traded on the tZERO Crypto's cryptocurrency trading platform.",
    headline: "What's the difference between XY Stock and XYO Tokens?",
    href: 'https://support.xy.company/hc/en-us/articles/360047628613',
  },
  {
    desc: 'No, XY Labs Stock is not a cryptocurrency. XY Labs Stock is Class A Common Stock of XY Labs. The stock represents ownership in the company. Even when the stocks digitized, the digitalization just creates a digital copy of our stock ownership viewable on the blockchain.',
    headline: 'Are XY Labs Stock a cryptocurrency?',
    href: 'https://www.tzero.com/digital-securities',
  },
  {
    desc: "We have an answer. Please take a moment to pursue investor questions in our help center, and if you need further assistance, just use the 'Submit a Request' button to get in touch with our specialists.",
    headline: 'I have another question.',
    href: 'https://support.xy.company/hc/en-us/sections/360006409073',
  },
]
