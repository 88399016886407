import { QuestionAnswerRounded } from '@mui/icons-material'
import { Divider } from '@mui/material'

import { BasicHero, CommonImages } from '../../common'
import BasePage from '../BasePage'
import InvestorsFaqSection from './Faq'
import InvestorsResourcesSection from './Resources'

const InvestorsPage: React.FC = () => {
  return (
    <BasePage name="Investors">
      <BasicHero
        title="Want to own a piece of XY Labs?"
        desc="We received qualification to sell our first round of Reg A+ Equity in 2016. We now have over 20,000 shareholders from around the world!"
        button1Text="Investor Resources"
        button1To="/investors/resources"
        button2To="/investors/how-to-buy"
        button2Text="How to Buy Equity"
        subLinkPath="/investors/support"
        subLinkText1="Need Help With Your Equity?"
        subLinkText2="Get Support Here."
        subLinkIcon={<QuestionAnswerRounded />}
        backgroundImage={CommonImages.floorPlantsWide}
        centeredAlways
      />
      <Divider />
      <InvestorsResourcesSection />
      <InvestorsFaqSection />
    </BasePage>
  )
}

export default InvestorsPage
