import { Organization } from '@xylabs/sdk-react'

// eslint-disable-next-line import/no-cycle
import { ArieTrouwPersonPartial } from '../ArieTrouw'
import FoundingLocation from '../FoundingLocation'
import { XyLabsPostalAddress } from '../PostalAddress'
import { XyLabsOrganizationPartial } from './Partial'

export const XyLabsOrganizationFull: Organization = {
  address: XyLabsPostalAddress,
  employee: [ArieTrouwPersonPartial],
  founder: ArieTrouwPersonPartial,
  foundingDate: '2012',
  foundingLocation: FoundingLocation,
  ...XyLabsOrganizationPartial,
}
