import { QuestionAnswerRounded } from '@mui/icons-material'
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexCol, FlexGrowRow, LinkEx } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { BasicHero, CommonImages, SimpleAccordionCard } from '../../../common'
import XyBasePage from '../../BasePage'
import { FaqData } from './FaqData'
import { RequestDepositInstructions } from './RequestDepositInstructions'
import { TzeroExplained } from './TzeroExplained'
import { TzeroInstructions } from './TzeroInstructions'

export const InvestorsTradePage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const anchors: Record<string, RefObject<HTMLDivElement>> = {
    requestDeposit: useRef<HTMLDivElement>(null),
    tzeroAccount: useRef<HTMLDivElement>(null),
    tzeroExplained: useRef<HTMLDivElement>(null),
    tzeroFaq: useRef<HTMLDivElement>(null),
  }
  const location = useLocation()
  const anchor = anchors[location.hash.slice(1)]

  useEffect(() => {
    if (anchor?.current) {
      anchor?.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [anchor])

  return (
    <XyBasePage name="Trade XY Stock">
      <BasicHero
        title="tZERO ATS + XY Labs"
        desc="XY Labs Class A common stock is trading on the leading market for digital securities, tZERO ATS. tZERO ATS, a FINRA member broker dealer, operates an SEC-regulated 'Alternative Trading System' that facilitates trading of digital securities."
        button1Text="Open an Account"
        button1Href="https://www.tzero.com/investors/register"
        button2Text="Step-By-Step Instructions"
        button2To="#tzeroAccount"
        subLinkText1="Have questions?"
        subLinkText2="View our tZERO FAQ"
        subLinkIcon={<QuestionAnswerRounded />}
        backgroundImage={isMobile ? '' : CommonImages.booksRight}
        subLinkPath="#tzeroFaq"
      />
      <Divider />
      <div id="tzeroExplained" ref={anchors.tzeroExplained}>
        <TzeroExplained />
      </div>
      <div id="tzeroAccount" ref={anchors.tzeroAccount}>
        <TzeroInstructions />
      </div>
      <div id="requestDeposit" ref={anchors.requestDeposit}>
        <RequestDepositInstructions />
      </div>
      <FlexGrowRow>
        <Container>
          <SectionSpacingRow>
            <Grid container id="tzeroFaq" ref={anchors.tzeroFaq}>
              <Grid item xs={12}>
                <Typography variant="h3" textAlign="center" gutterBottom paddingBottom={2}>
                  tZERO FAQ
                </Typography>
              </Grid>
              {FaqData.map(({ headline, desc, to, href }, index) => {
                return (
                  <Grid item key={index} xs={12}>
                    <FlexCol justifyContent="flex-start" alignItems="stretch">
                      <SimpleAccordionCard
                        dark={!(index % 2)}
                        expanded={expanded === index + 'card'}
                        onChange={handleChange(index + 'card')}
                        headline={headline}
                        desc={desc}
                        to={to}
                        href={href}
                      />
                    </FlexCol>
                  </Grid>
                )
              })}
              <Grid item xs={12}>
                <Typography paddingTop={3}>
                  <FlexGrowRow width="100%" justifyContent="center">
                    <QuestionAnswerRounded /> &nbsp;
                    <Typography>Have more questions about XY Labs Stock?&nbsp;</Typography>
                    {isMobile ? null : (
                      <LinkEx href="/investors/support" underline="always" target="_blank" color="inherit">
                        Get Support Here.
                      </LinkEx>
                    )}
                  </FlexGrowRow>
                </Typography>
              </Grid>
            </Grid>
          </SectionSpacingRow>
        </Container>
      </FlexGrowRow>
    </XyBasePage>
  )
}
