import { Container, Grid, Typography } from '@mui/material'
import { FlexCol, FlexGrowRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import { useState } from 'react'

import JobAccordionCard from './JobAccordionCard'
import { marketingRevenueOpportunities, operationsSupportOpportunities, productDevelopmentOpportunities } from './opportunityData'

const ListOfAvailableJobs: React.FC = () => {
  const [accordionOpenItem, setAccordionOpenItem] = useState(-1)

  return (
    <FlexGrowRow>
      <Container>
        <SectionSpacingRow>
          <Grid container>
            <Typography variant="h3" padding={2}>
              Development
            </Typography>
            {productDevelopmentOpportunities.map((job, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <FlexCol justifyContent="flex-start" alignItems="stretch" margin={0.5}>
                    <JobAccordionCard
                      jobTitle={job.jobTitle}
                      desc={job.desc}
                      compensation={job.compensation}
                      experience={job.experience}
                      tools={job.tools}
                      key={index}
                      dark={!(index % 2)}
                      expanded={accordionOpenItem === index}
                      onChange={(event, expanded) => setAccordionOpenItem(expanded ? index : -1)}
                    />
                  </FlexCol>
                </Grid>
              )
            })}
          </Grid>
        </SectionSpacingRow>
        <SectionSpacingRow>
          <Grid container>
            <Typography variant="h3" padding={2}>
              Marketing
            </Typography>
            {marketingRevenueOpportunities.map((job, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <FlexCol justifyContent="flex-start" alignItems="stretch" margin={0.5}>
                    <JobAccordionCard
                      jobTitle={job.jobTitle}
                      desc={job.desc}
                      compensation={job.compensation}
                      experience={job.experience}
                      tools={job.tools}
                      key={index}
                      dark={!(index % 2)}
                      expanded={accordionOpenItem === index}
                      onChange={(event, expanded) => setAccordionOpenItem(expanded ? index : -1)}
                    />
                  </FlexCol>
                </Grid>
              )
            })}
          </Grid>
        </SectionSpacingRow>
        <SectionSpacingRow>
          <Grid container>
            <Typography variant="h3" padding={2}>
              Operations &amp; Support
            </Typography>
            {operationsSupportOpportunities.map((job, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <FlexCol justifyContent="flex-start" alignItems="stretch" margin={0.5}>
                    <JobAccordionCard
                      jobTitle={job.jobTitle}
                      desc={job.desc}
                      compensation={job.compensation}
                      experience={job.experience}
                      tools={job.tools}
                      key={index}
                      dark={!(index % 2)}
                      expanded={accordionOpenItem === index}
                      onChange={(event, expanded) => setAccordionOpenItem(expanded ? index : -1)}
                    />
                  </FlexCol>
                </Grid>
              )
            })}
          </Grid>
        </SectionSpacingRow>
      </Container>
    </FlexGrowRow>
  )
}

export default ListOfAvailableJobs
