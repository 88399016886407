import { BusyBoxProps, RedirectWithQuery } from '@xylabs/sdk-react'
import { Route, Routes } from 'react-router-dom'

import InvestorsPage from './Investors'
import { InvestorsFaqPage } from './InvestorsFaq'
import { InvestorsHowToBuyPage } from './InvestorsHowToBuy'
import { InvestorsPortalPage } from './InvestorsPortal'
import { InvestorsResourcesPage } from './InvestorsResources'
import { InvestorsSupportPage } from './InvestorsSupport'
import { InvestorsTradePage } from './InvestorsTrade'

export const InvestorRoutes: React.FC<BusyBoxProps> = () => {
  return (
    <Routes>
      {/* Investor Pages & Redirects */}
      <Route element={<InvestorsPage />} path="/" />
      <Route element={<RedirectWithQuery to="/investors" />} path="*" />
      <Route element={<RedirectWithQuery to="/investors" />} path="/equity" />
      <Route element={<InvestorsResourcesPage />} path="/resources" />
      <Route element={<RedirectWithQuery to="/resources" />} path="/equity/resources" />
      <Route element={<InvestorsPortalPage />} path="/portal" />
      <Route element={<InvestorsTradePage />} path="/trade" />
      <Route element={<InvestorsHowToBuyPage />} path="/how-to-buy" />
      <Route element={<RedirectWithQuery to="/how-to-buy" />} path="/equity/how-to-buy" />
      <Route element={<InvestorsFaqPage />} path="/faq" />
      <Route element={<RedirectWithQuery to="/faq" />} path="/equity/faq" />
      <Route element={<InvestorsSupportPage />} path="/support" />
      <Route element={<RedirectWithQuery to="/support" />} path="/equity/support" />
    </Routes>
  )
}
