import { useTheme } from '@mui/material'
import { AppBarEx, AppBarExProps } from '@xylabs/sdk-react'

import ContextToolbar from './ContextToolbar'
import JobsSystemToolBar from './JobsSystemToolbar'

const Header: React.FC<AppBarExProps> = ({ contextToolbar, systemToolbar, ...props }) => {
  const theme = useTheme()
  return (
    <AppBarEx
      style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }}
      container="lg"
      contextToolbar={contextToolbar ?? <ContextToolbar />}
      systemToolbar={systemToolbar ?? <JobsSystemToolBar />}
      {...props}
    />
  )
}

export default Header
